export const instructions = `

    agent_template={幻星}//这是agent模板名称，用于激活内部的agent

    //以下补充信息，如果没有可不写：

    bot_info={
    }
    
    user_info={
    }

    extra_rules={
    }//这是追加的全局回复要求（agent内置还有一些，尽量精简集中）

    extra_skills={
    }//这是追加的纯prompt skills`;
